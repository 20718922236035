import service from 'common/service/SessionService.js';
import { blackbox } from 'common/util/IovationUtil';
import trackingStore from 'app/stores/trackingStore';
import uniqueDeviceIDStore from 'app/stores/uniqueDeviceIDStore';
import viewportState from 'stores/ViewportState';
import deviceState from 'stores/DeviceState';
import routing from 'app/stores/routing';

export default function(
  user,
  pass,
  siteId,
  twoFaToken,
  BrandCode,
  additionalSecurity,
  geolocationDetails,
) {
  return new Promise((resolve, reject) => {
    if (App.session.request('loggedIn')) {
      resolve();

      return; // needed? does resolving the promise interupt the call stack?
    }

    const deviceContext = App.Features('login.deviceContextV1')
      ? `site=${viewportState.isMobile ? 'MOBILE' : 'NORMAL'}`
      : deviceState.buildDeviceDetails;

    const deviceId = uniqueDeviceIDStore.uniqueDeviceID;

    service
      .login(
        user,
        pass,
        siteId,
        twoFaToken,
        BrandCode,
        deviceId,
        blackbox,
        deviceContext,
        additionalSecurity
          ? JSON.stringify({ Security: additionalSecurity })
          : '',
        geolocationDetails,
      )
      .then(resp => {
        App.session.execute('storeSession', resp.Login);
        import('stores/GeoLocationService').then(store => {
          store.default.callCheck('LOGGED_IN', resp.Login?.accountId);
        });
        trackingStore.afterLogin(resp.Login.accountId, {
          email: resp.Login.email,
          id: resp.Login.accountId,
          name: resp.Login.name,
          username: resp.Login.username,
        });
        resolve(resp);
        if (
          App.Features('casino.affiliate') &&
          ((Array.isArray(App.Features('casino.affiliate')) &&
            App.Features('casino.affiliate').includes(
              resp.Login.affiliateSystemId,
            )) ||
            resp.Login.affiliateSystemId === App.Features('casino.affiliate'))
        ) {
          App.navigate('/casino');
        }
        if (routing.shouldRedirectToRootDomain) {
          window.location.replace(
            `https://${App.Features('redirectToRootDomain.rootDomain')}`,
          );
        }
      })
      .catch(resp => {
        App.session.execute('clearSession');
        reject(resp);
      });
  });
}
