import { format } from 'app/util/date';

class HyrosTracking {
  service;
  PEA;

  loadImports = async () => {
    App.Import(
      import('sportsbook/service/SportsbookRestfulService'),
      'service',
      this,
    );
    App.Import(import('modules/pea/model/PersonalDetailsModel'), 'PEA', this);
  };

  track = async (type, payload) => {
    if (App?.Globals?.isDevUat || !App.Features('tracking.hyros.key')) {
      return;
    }

    if (!this.service || !this.PEA) {
      await this.loadImports();
    }
    switch (type) {
      case 'deposit_success':
        this.service.trackSuccessfulDeposit({
          email: this.PEA.get('email1'),
          firstName: this.PEA.get('firstName'),
          lastName: this.PEA.get('surname'),
          leadIps: [''],
          orderId: '',
          cartId: '',
          date: format(new Date(), 'YYYY-MM-DDTHH:MM:SS'),
          priceFormat: App.Globals.priceFormat,
          currency: this.PEA.get('currency'),
          taxes: 0,
          items: [
            {
              name: payload?.transactionNumber === 1 ? 'FTD' : 'Deposit',
              price: payload.amount,
            },
          ],
        });
        break;
    }
  };
}

const store = new HyrosTracking();
export default store;
