/* eslint-disable no-param-reassign */

import { observable } from 'mobx';

window.dataLayer = window.dataLayer || [];

class GtmTracking {
  @observable.ref
  store = {};
  @observable.ref
  game = {};

  init = async () => {
    await import('app/stores/rgsCasinoStore').then(rgsStore => {
      this.store = rgsStore.default;
    });
  };

  track = async (event, data = {}) => {
    if (!App.Features('tracking.gtm.enabled')) {
      return;
    }

    if (Object.keys(App.Features('tracking.gtm.eventConfig')).includes(event)) {
      window.dataLayer.push(this.getEventProps(event, data));
    }
  };

  trackNav = (route, metadata) => {
    if (!route) return;
    this.track('page_view', metadata);
  };

  getEventProps = (event, data) => {
    const eventConfig = App.Features('tracking.gtm.eventConfig');

    const eventProps = eventConfig[event];

    if (!eventProps) {
      return { event };
    }

    if (this.store && event.includes('casino')) {
      this.game = this.store.selectedGame;
      data.gameName = this.game.name;
      data.gameType = this.game.category;
    }

    const resolvedProps = {};

    Object.entries(eventProps).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      resolvedProps[key] = typeof value === 'function' ? value(data) : value;
    });

    return resolvedProps;
  };
}

const store = new GtmTracking();
export default store;
