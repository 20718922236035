import TheTradeDesk from 'thirdparty/theTradeDesk';

class TheTradeDeskTracking {
  track = async (eventName = '', metaData = {}) => {
    if (!window.ttd_dom_ready)
      await TheTradeDesk.init({ tracking: App.Features('tracking') });

    const eventKey = App.Features(
      `tracking.theTradeDesk.eventKey.${eventName}`,
    );
    if (!eventKey) return;

    const link =
      this.baseLink(eventKey) + this.paramMapper(eventName, metaData);
    fetch(link);
  };

  paramMapper = (eventName, metaData) => {
    if (typeof metaData !== 'object' || metaData === null) return '';

    let res = '';

    Object.values(metaData).forEach((value, i) => {
      res += `&td${(i + 1).toString()}=${value}`;
    });

    switch (eventName) {
      case 'deposit_success':
      case 'first_deposit_success':
        res += `&v=${metaData.amount}`;
        break;
      default:
        break;
    }

    return res;
  };

  baseLink = eventID =>
    `https://insight.adsrvr.org/track/pxl/?adv=${App.Features(
      'tracking.theTradeDesk.ADVERTISER_ID',
    )}&ct=${eventID}&fmt=3`;
}

const inst = new TheTradeDeskTracking();
export default inst;
