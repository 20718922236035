import { loadAsync } from 'app/util/Utils';

class xAds {
  baseScript = 'https://static.ads-twitter.com/uwt.js';

  init = async features => {
    if (window.twq || !features.tracking.xAds.enabled) return;

    const s = (window.twq = function() {
      s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
    });
    s.version = '1.1';
    s.queue = [];

    await loadAsync(this.baseScript, () => {
      twq('config', features.tracking.xAds.key);
    });
  };
}

const inst = new xAds();
export default inst;
