import ats from 'ats';

const actions = ats.analytics.actions;

class BetRadarTracking {
  previousTransaction;
  previousFirstDeposit;

  init() {
    window.srtmCommands = window.srtmCommands || [];
    const j = document.createElement('script');
    const f = document.getElementsByTagName('script')[0];
    j.src = `https://tm.ads.sportradar.com/dist/tag-manager.js?id=${App.Features(
      'sportradar.tracking.key',
      '',
    )}`;
    j.asnyc = true;
    j.id = 'sportradar-tracking-script';
    j.setAttribute('data-srtmn', 'srtmCommands');
    f.parentNode.insertBefore(j, f);
  }

  track = (type, payload = {}) => {
    switch (type) {
      case actions.registerSuccess:
        srtmCommands.push({
          event: 'track.user.registration',
          payload: { action: 'complete', userId: payload?.id },
        });
        return;
      case actions.loginSuccess:
        srtmCommands.push({
          event: 'track.user.login',
          payload: { action: 'complete', userId: payload?.id },
        });
        return;
      case actions.depositSuccess:
        if (
          !payload?.transactionId ||
          payload?.transactionId === this.previousTransaction
        ) {
          return;
        }
        this.previousTransaction = payload?.transactionId;
        srtmCommands.push({
          event: 'track.betting.deposit',
          payload: {
            action: 'created',
            userId: payload?.id,
            transactionId: payload?.transactionId || '',
            amount: payload?.amount,
            currency: App.session.request('currency'),
          },
        });
        return;
      case actions.firstDepositSuccess:
        if (
          !payload?.transactionId ||
          payload?.transactionId === this.previousFirstDeposit
        ) {
          return;
        }
        this.previousFirstDeposit = payload?.transactionId;
        srtmCommands.push({
          event: 'track.betting.deposit',
          payload: {
            action: 'created',
            label: 'first_deposit',
            userId: payload?.id,
            transactionId: payload?.transactionId || '',
            amount: payload?.amount || 0,
            currency: App.session.request('currency'),
          },
        });
        return;
      case actions.joinnowClicked:
        srtmCommands.push({
          event: 'track.user.registration',
          payload: { action: 'start' },
        });
        return;
      case 'eventPage':
        srtmCommands.push({
          event: 'srt.retargeting',
          payload: { sportsEvent: payload.sportsEvent },
        });
        return;
      default:
        '';
    }
  };
}

const store = new BetRadarTracking();

export default store;
