import { loadAsync } from 'app/util/Utils';

class BingAds {
  init = async features => {
    if (window.UET || !features.tracking.bingAds.enabled) return;
    await loadAsync('https://bat.bing.com/bat.js');
  };
}

const inst = new BingAds();
export default inst;
