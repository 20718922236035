import { action, observable, makeObservable } from 'mobx';

const ua = navigator.userAgent;
const platform = navigator.platform;

class DeviceState {
  isIphone =
    ua.match(/ipad|ipod|iphone/i) ||
    (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  isFirefox = ua.toLowerCase().includes('firefox');
  isEdge = ua.toLowerCase().includes('edge');
  isWindows = ua.toLowerCase().includes('windows');
  isLinux = /linux(?!.*android)/i.test(ua);
  isMac = ua.toLowerCase().includes('macintosh');
  isAndroid = /android/i.test(ua);

  appType = false;

  constructor() {
    makeObservable(this);
    window.addEventListener('touchstart', this.onFirstTouch, false);
  }

  @observable
  hover = true;

  @action
  onFirstTouch = () => {
    this.hover = false;
    window.removeEventListener('touchstart', this.onFirstTouch, false);
  };

  get mobileDevice() {
    return /Mobile/i.test(ua) || this.iphone;
  }

  get android() {
    return /Android/i.test(ua);
  }

  get linux() {
    return /Linux/i.test(ua) && !this.android;
  }

  get mac() {
    return /Mac OS X/i.test(ua) && !this.iphone;
  }

  get windows() {
    return /Windows NT/i.test(ua);
  }

  get iphone() {
    return /iPhone/i.test(ua);
  }

  get kindle() {
    return /Kindle/i.test(ua);
  }

  get iPad() {
    return /iPad/i.test(ua) || (this.mac && navigator.maxTouchPoints > 1);
  }

  get androidTablet() {
    return this.android && !this.mobileDevice;
  }

  get isTablet() {
    return this.iPad || this.androidTablet || this.kindle;
  }

  get isMobile() {
    return this.mobileDevice && !this.isTablet; // Ensure mobile phones are not confused with tablets
  }

  get ios() {
    return this.iphone || this.iPad;
  }

  get osType() {
    if (this.appType) return this.appType?.toUpperCase();
    if (this.ios) return 'IOS';
    if (this.mac) return 'OSX';
    if (this.windows) return 'WINDOWS';
    if (this.linux) return 'LINUX';
    if (this.android) return 'ANDROID';
    return 'OTHER';
  }

  get siteType() {
    if (this.isMobile) return this.appType ? 'MOBILE_APP' : 'MOBILE_WEB';
    if (this.isTablet) return this.appType ? 'TABLET_APP' : 'TABLET_WEB';
    return 'DESKTOP';
  }

  get buildDeviceDetails() {
    return `site=${this.siteType};os-type=${this.osType}`;
  }
}

const store = new DeviceState();
export default store;
