import segment from 'stores/Segment';
import airship from 'stores/AirshipStore';
import appsFlyer from 'stores/AppsFlyer';
import OptimoveStore from 'stores/OptimoveStore';
import adnxs from 'stores/AdnxsPixelTracking';
import mixtraff from 'stores/MixtraffTracking';
import gsm from 'stores/GSMTracking';
import ecommerce from 'stores/EcommerceTracking';
import gaEvent from 'stores/GAEventTracking';
import facebookPT from 'stores/FacebookPixelTracking';
import gtmTracking from 'stores/GtmTracking';
import Cookies from 'stores/CookieStore';
import hyros from 'stores/HyrosTracking';
import SportRadarTracking from 'stores/SportRadarTracking';
import TheTradeDeskTracking from 'stores/theTradeDeskTracking';
import BingAdsTracking from 'stores/BingAdsTracking';
import XAdsTracking from 'stores/XAdsTracking';
import ats from 'ats';
import loadComp from 'app/util/loadComp';

const { analytics } = ats;

class TrackingStore {
  init = async () => {
    analytics.start(
      this.adTrackingEnabled,
      this.trackingEnabled,
      'WEB',
      this.loadTrackingProviders,
    );
  };

  get trackingEnabled() {
    return App.Globals.isAnalyticsEnabled;
  }

  get adTrackingEnabled() {
    if (App.Features('cookiesPolicyBar.enabled')) {
      return (
        Cookies.get('cookies-policy.accepted') === 'true' ||
        (App.Globals.allowDevAnalytics && App.Globals.isDevUat)
      );
    }
    return true;
  }

  loadTrackingProviders = async () => {
    const IFrameStore = await loadComp(import('stores/IFrameStore'));
    return [
      {
        name: 'segment',
        enabled: App.Features('tracking.segment.enabled'),
        store: segment,
        track: segment.track,
        identify: segment.identify,
        page: segment.page,
      },
      {
        name: 'adnxs',
        enabled: App.Features('tracking.adnxs.enabled'),
        store: adnxs,
        track: adnxs.track,
      },
      {
        name: 'mixtraff',
        enabled: App.Features('tracking.mixtraff.enabled'),
        store: mixtraff,
        track: mixtraff.track,
      },
      {
        name: 'gsm',
        enabled: App.Features('tracking.gsm.enabled'),
        store: gsm,
        track: gsm.track,
      },
      {
        name: 'ecommerce',
        enabled: App.Features('tracking.ecommerce.enabled'),
        store: ecommerce,
        track: ecommerce.track,
      },
      {
        name: 'facebookPT',
        enabled: App.Features('tracking.facebookPT.enabled'),
        store: facebookPT,
        track: facebookPT.track,
      },
      {
        name: 'gaEvent',
        enabled: App.Features('tracking.gaEvent.enabled'),
        store: gaEvent,
        track: gaEvent.track,
      },
      {
        name: 'appsFlyer',
        enabled: App.Features('tracking.appsFlyer.enabled'),
        store: appsFlyer,
        track: appsFlyer.track,
        init: appsFlyer.init,
      },
      {
        name: 'gtmTracking',
        enabled: App.Features('tracking.gtm.enabled'),
        store: gtmTracking,
        init: gtmTracking.init,
        track: gtmTracking.track,
        trackNavigation: App.Features('tracking.gtm.enabled'),
        trackNav: gtmTracking.trackNav,
      },
      {
        name: 'airship',
        enabled: App.Features('tracking.airship.enabled'),
        store: airship,
        init: airship.init,
      },
      {
        name: 'hyros',
        enabled: App.Features('tracking.hyros.key'),
        store: hyros,
        track: hyros.track,
      },
      {
        name: 'optimove',
        enabled: App.Features('tracking.optimove.enabled'),
        store: OptimoveStore,
        track: OptimoveStore.track,
        init: OptimoveStore.init,
      },
      {
        name: 'iFrame',
        enabled: App.Features('tracking.iFrame.enabled'),
        store: IFrameStore,
        track: IFrameStore.track,
        trackNavigation: App.Features('tracking.iFrame.enabled'),
        trackNav: IFrameStore.trackNav,
        init: IFrameStore.init,
      },
      {
        name: 'gtag',
        enabled:
          App.Features('tracking.gtagEvent.enabled') &&
          App.Features('tracking.gtagEvent.id') &&
          window.gtag,
        track: type =>
          window.gtag('event', type, {
            send_to: App.Features('tracking.gtagEvent.id'),
          }),
      },
      {
        name: 'sportradar',
        enabled: App.Features('tracking.sportradar.enabled'),
        store: SportRadarTracking,
        track: SportRadarTracking.track,
        init: SportRadarTracking.init,
      },
      {
        name: 'thetradedesk',
        enabled: App.Features('tracking.theTradeDesk.enabled'),
        store: TheTradeDeskTracking,
        track: TheTradeDeskTracking.track,
      },
      {
        name: 'bingAds',
        enabled: App.Features('tracking.bingAds.enabled'),
        store: BingAdsTracking,
        track: BingAdsTracking.track,
      },
      {
        name: 'xAds',
        enabled: App.Features('tracking.xAds.enabled'),
        store: XAdsTracking,
        track: XAdsTracking.track,
      },
    ];
  };

  afterLogin = async (userId = undefined, traits = {}) => {
    if (App.Features('tracking.airship.enabled')) {
      await airship.afterLogin();
    }

    if (userId && App.Features('tracking.segment.enabled')) {
      segment.identify(userId, traits);
    }

    if (App.Features('tracking.appsFlyer.enabled')) {
      appsFlyer.identify(userId);
    }

    if (App.Features('tracking.optimove.enabled')) {
      OptimoveStore.setUserID(userId);
      OptimoveStore.setUserEmail(traits?.email);
    }
  };

  identify = (userId = '', traits) => {
    if (this.trackingEnabled && analytics.trackingProviders?.length) {
      analytics.identify(userId, traits);
    }
  };

  page = () => {
    if (this.trackingEnabled && analytics.trackingProviders?.length) {
      analytics?.page?.();
    }
  };

  track = async (action = '', data = {}, route = '', providerName = '') => {
    if (!this.trackingEnabled) {
      return;
    }
    if (analytics.trackingProviders?.length) {
      analytics.track(action, data, route, providerName);
    }
  };
}

const inst = new TrackingStore();
export default inst;
