import Cookies from 'js-cookie';
import { action } from 'mobx';

class CookieStore {
  @action
  set = (name, value, options = {}) => {
    // eslint-disable-next-line no-undef
    if (__FEATURES__.external.iframe) {
      // eslint-disable-next-line no-param-reassign
      options.sameSite = 'none';
    }

    Cookies.set(name, value, {
      ...options,
      secure: true,
    });
  };

  @action
  remove = (name, options) => {
    Cookies.remove(name, options);
  };

  @action
  get = name => Cookies.get(name);

  @action
  getJSON = name => Cookies.getJSON(name);
}

const store = new CookieStore();
export default store;
