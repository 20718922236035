const TYPE_MAP = {
  login_clicked: 'login_clicked',
  deposit_success: 'deposit_success',
  withdraw_success: 'withdraw_success',
  register_success: 'register_success',
  register_submitted: 'register_submitted',
  register_verifyid: 'register_verifyid',
  placebet_clicked: 'placebet_clicked',
  placebet_success: 'placebet_success',
  'placebet_success_{SPORT}': 'placebet_success_{SPORT}',
  placebet_failed: 'placebet_failed',
  joinnow_clicked: 'joinnow_clicked',
  first_deposit_success: 'first_deposit_success',
  casino_game_open: 'casino_game_open',
  casino_game_open_live: 'casino_game_open_live',
  register_start: 'register_start',
  register_email_confirmed: 'register_email_confirmed',
};

const DEEPLINK_ROUTE_VALUES = ['home', 'promotions', 'sports'];

class AppsFlyer {
  init = async () => {
    const hasSmartBanners = App.Features('tracking.appsFlyer.smartBanners');
    const appsflyer = hasSmartBanners
      ? await import('thirdparty/appsflyer/appsflyerSmartBanners')
      : await import('thirdparty/appsflyer/appsflyer');
    if (appsflyer) {
      const initialise = appsflyer?.default;
      if (hasSmartBanners) {
        initialise?.(
          App.Features('tracking.appsFlyer.webKey'),
          App.Features('tracking.appsFlyer.smartBannerKey'),
        );
        const appsflyerOneLinkSmartScript = await import(
          'thirdparty/appsflyer/appsflyerOneLinkSmartScript'
        );
        const smartScript = appsflyerOneLinkSmartScript?.default;
        smartScript?.();
      } else {
        initialise?.(App.Features('tracking.appsFlyer.webKey'));
      }
    }
  };

  track = async (key, { amount = null }) => {
    if (!this.isEventValid(key)) {
      return;
    }
    if (!window.AF) {
      await this.init();
    }
    if (amount) {
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: key,
        eventRevenue: amount,
      });
    }
    window.AF('pba', 'event', { eventType: 'EVENT', eventName: key });
  };

  identify = async userId => {
    window.AF('pba', 'setCustomerUserId', String(userId));
  };

  isEventValid = event => {
    const templateRegexp = /{\w+}/;
    return Object.keys(TYPE_MAP).find(allowedEvent => {
      const templateIndex = allowedEvent
        .split('_')
        .findIndex(eventChunk => templateRegexp.test(eventChunk));
      if (templateIndex !== -1) {
        return (
          allowedEvent.replace(
            templateRegexp,
            event.split('_')[templateIndex],
          ) === event
        );
      }
      return allowedEvent === event;
    });
  };
}

const inst = __FEATURES__.tracking.appsFlyer.enabled && new AppsFlyer();
export default inst;
