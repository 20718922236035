import { loadTemplateColors, loadTemplateVariables } from 'templates/utils';
import Cookies from 'stores/CookieStore';

const FALLBACK_SKIN = 'amelco';

const skinFromCookiesIfPresent = () => {
  const skin = Cookies.get('skin');
  return __THEMES__.includes(skin) ? skin : FALLBACK_SKIN;
};

export const getCurrentSkin = () => {
  const skinName = __SKIN_NAME__;
  if ((skinName === 'amelco' || __DEV__) && Cookies.get('skin')) {
    return skinFromCookiesIfPresent();
  }
  return __SKIN_NAME__;
};

export const getCurrentLangFolder = () => {
  if (__DEV__ && Cookies.get('skin')) {
    return `${skinFromCookiesIfPresent()}-sportsbook`;
  }
  return __I18N_FOLDER__;
};

const loadColors = async skin => {
  const { default: colorLoader } = await import('app/theme/colorLoader');
  const colors = await import('app/theme/colors');
  let skinColors;
  let templateColors;
  try {
    templateColors = await loadTemplateColors('whitelabel1', skin);
  } catch (e) {
    //
  }
  try {
    skinColors = await import(
      /* webpackChunkName: "SkinColors" */ `themes/${skin}/app/theme/skinColors`
    );
  } catch (e) {
    //
  }

  const colorsObject = skinColors?.default || templateColors;
  const cs = colorLoader(colorsObject);
  colors.setColors(cs);
  return { colors: cs, componentLibrary: colorsObject.componentLibrary };
};

const loadVariables = async (skin, colors) => {
  const { default: variablesLoader } = await import(
    'app/theme/variablesLoader'
  );
  const variables = await import('app/theme/variables');

  let skinVariables;
  let skinVariablesMobile;
  let templateVariables;
  let templateVariablesMobile;

  try {
    skinVariables = await import(
      /* webpackChunkName: "SkinVariables" */ `themes/${skin}/app/theme/skinVariables`
    );
  } catch (e) {
    //
  }

  if (App.viewport.isMobile) {
    try {
      skinVariablesMobile = await import(
        /* webpackChunkName: "SkinVariablesMobile" */ `themes/${skin}/app/theme/skinVariables.m.js`
      );
    } catch (e) {
      console.warn(`Error loading mobile skin variables for ${skin}`, e);
    }
  }

  try {
    templateVariables = await loadTemplateVariables('whitelabel1', colors);
  } catch (e) {
    //
  }

  if (App.viewport.isMobile) {
    try {
      templateVariablesMobile = await loadTemplateVariables(
        'whitelabel1',
        colors,
        true,
      );
    } catch (e) {
      //
    }
  }

  const vs = variablesLoader(
    skinVariables?.default || templateVariables,
    skinVariablesMobile?.default || templateVariablesMobile,
  );
  variables.setVariables(vs);
  return vs;
};

const applyStyles = async (colors, variables) => {
  await import('app/styler');
  const aesthetic = await import('app/theme/aesthetic');
  aesthetic.themes.sportsbook.colors = { ...colors };
  aesthetic.themes.sportsbook.variables = { ...variables };
  App.variables = aesthetic.themes.sportsbook.variables;
};

export const loadStyles = async onSuccess => {
  try {
    const skin = getCurrentSkin();
    const colors = await loadColors(skin);
    const variables = await loadVariables(skin, colors.colors);

    await applyStyles(colors.colors, variables);

    const complibraryColors = colors?.componentLibrary;
    if (complibraryColors) {
      window.atsComponents = {
        colors: complibraryColors,
      };
    }
    if (onSuccess) onSuccess();
  } catch (error) {
    console.error('Error loading styles', error);
  }
};
