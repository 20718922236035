import deepMerge from 'deepmerge';
import { getCurrentSkin } from '../app/util/ThemeUtils';
import defaultFeatres from './defaultFeatures';

// we want to merge the destination with source but when coming accros an array replace it entirely
const dontMerge = (destination, source) => source;
const features = async (skin = process.env.SKIN) => {
  if (
    __FEATURES__.external.enabled &&
    !__DEV__ &&
    !__FEATURES__.external.iframe
  ) {
    const publicPath = `${config.domain.url}/`;
    console.log(`setting webpack public path as: ${publicPath}`);
    __webpack_public_path__ = publicPath;
  }

  try {
    skin = getCurrentSkin();
  } catch (e) {}

  let skinFeatures = {};
  let skinFeaturesMobile = {};
  let modeFeatures = {};
  let modePriorityOverSkin = false;
  let mode = '';
  if (skin) {
    const skinName = skin.replace('-sportsbook-skin', '');

    const sf = await import(
      /* webpackChunkName: "Skin Features" */ `themes/${skinName}/config/skinFeatures`
    );
    skinFeatures = sf.default;
    mode = skinFeatures?.mode;
    try {
      try {
        if (mode) {
          const mf = await import(
            /* webpackChunkName: "Features mode" */ `./modes/${mode}`
          );
          if (mode === 'african_retail') {
            modePriorityOverSkin = true;
          }
          modeFeatures = mf.default;
        }
      } catch (e) {}
    } catch (e) {}

    if (document?.documentElement?.clientWidth < 544) {
      try {
        const sfmob = await import(
          /* webpackChunkName: "Skin Features Mobile" */ `themes/${skinName}/config/skinFeatures.m`
        );
        skinFeaturesMobile = sfmob.default;
      } catch (e) {}
    }
  }

  return deepMerge.all(
    modePriorityOverSkin
      ? [defaultFeatres, skinFeatures, skinFeaturesMobile, modeFeatures]
      : [defaultFeatres, modeFeatures, skinFeatures, skinFeaturesMobile],
    {
      arrayMerge: dontMerge,
    },
  );
};

export const setRegionFeatures = async () => {
  let regionFeatures;
  try {
    const skin = App.Globals.skin;
    const regionCode = App.Globals.regionCode.toLowerCase();

    const sfregion = await import(
      /* webpackChunkName: "Skin Features Region" */ `themes/${skin}/config/regions/skinFeatures.${regionCode}.js`
    );
    regionFeatures = sfregion.default;
  } catch (e) {
    console.error('Error importing region features:', e);
  }

  if (regionFeatures) {
    App.featuresObject = deepMerge.all([App.featuresObject, regionFeatures], {
      arrayMerge: (destination, source) => source,
    });
  }
};

export default features;
