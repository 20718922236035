import viewportState from 'stores/ViewportState';
import { observable, action, computed, makeObservable } from 'mobx';
import Menu from './Menu';
import SectionController from './SectionController';
import trackingStore from 'app/stores/trackingStore';
import ats from 'ats';

const actions = ats.analytics.actions;

const betslipInLeftNav = __FEATURES__.betslip.inLeftNav;

class BurgerMenu {
  _betslip = new Menu();
  _favourites = new Menu(!betslipInLeftNav);
  _search = new Menu();
  _allSports = new Menu(true);
  _raceCards = new Menu();
  _quickLinks = new Menu(true);
  _oddsSwitcher = new Menu(true);

  constructor() {
    makeObservable(this);
    this.allSports = betslipInLeftNav
      ? new SectionController(this, '_allSports')
      : {
          isOpen: undefined,
          toggle: undefined,
          open: undefined,
          close: undefined,
        };

    this.raceCards = {
      isOpen: undefined,
      toggle: undefined,
      open: undefined,
      close: undefined,
    };
  }

  @observable
  isOpen = false;

  @action
  toggle = () => {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      trackingStore.track(actions.menuClicked);
    }
  };

  @action
  open = () => {
    this.isOpen = true;
  };

  @action
  close = () => {
    this.isOpen = false;
  };

  closeAll = () => {
    this._betslip.close();
    this._favourites.close();
    this._search.close();
    this._allSports.close();
    this._raceCards.close();
    this._quickLinks.close();
    this._oddsSwitcher.close();
  };

  @computed
  get lockBody() {
    return this.isOpen && viewportState.isSmallerThanBreakpoint('lgxl');
  }

  betslip = new SectionController(this, '_betslip');
  favourites = new SectionController(this, '_favourites');
  search = new SectionController(this, '_search');
  quickLinks = new SectionController(this, '_quickLinks');
  oddsSwitcher = new SectionController(this, '_oddsSwitcher');
}

export default BurgerMenu;
