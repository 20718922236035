import { debounce } from 'underscore';
import viewportState from 'stores/ViewportState';
import MenuState from 'stores/MenuState';

const sendWindowHeight = iframeSize => {
  if (!iframeSize) return;

  const message = { type: 'iframeSize', value: iframeSize };
  window.parent.postMessage(JSON.stringify(message), '*');
};

const debouncedSendWindowHeight = debounce(sendWindowHeight, 500);

const calculateHeight = (tagID = 'main-content') => {
  // Return when no parent is available
  if (!window.parent || window.location === window.parent.location) return;

  // Return when the betslip is displayed and the main content would resize
  if (tagID !== 'betslip' && MenuState.betslip.isOpen) return;

  // Return when the betslip would resize in desktop mode
  if (
    tagID === 'betslip' &&
    (!viewportState.isMobile || !MenuState.betslip.isOpen)
  )
    return;

  let elementHeight, iframeSize;
  const navbarHeight = document.getElementById('navbar').clientHeight;

  if (tagID === 'betslip') {
    elementHeight = document.getElementById(tagID).firstElementChild
      ?.clientHeight;
    iframeSize = elementHeight;
  } else {
    elementHeight = document.getElementById(tagID).clientHeight;
    iframeSize =
      elementHeight >= navbarHeight
        ? elementHeight +
          (viewportState.isSmallerThanBreakpoint('md') ? navbarHeight : 0)
        : navbarHeight;
  }

  debouncedSendWindowHeight(iframeSize);
};

export default calculateHeight;
