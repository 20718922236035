export const loadTemplateColors = async (templateName, skin) => {
  const brandColors = (await import(`themes/${skin}/app/theme/brandColors`))
    .default;
  const { default: generateColors } = await import(
    `templates/${templateName}/colors`
  );
  const { getThemeColors } = await import(`app/util/colorUtils`);
  const { compBaseColors, compColors } = await import(
    `templates/${templateName}/compColors`
  );

  const baseColors = compBaseColors(brandColors);
  const compColorList = compColors(brandColors);

  const compLibColors = {
    ...getThemeColors(baseColors),
    ...getThemeColors(compColorList),
  };

  const generatedColors = generateColors(brandColors);
  const skinColors = generatedColors.skinColors;
  const skinComponentColors = generatedColors.skinComponentColors;

  return {
    skinColors,
    skinComponentColors,
    componentLibrary: compLibColors,
  };
};

export const loadTemplateVariables = async (
  templateName,
  colors,
  isMobile = false,
) => {
  const getVariables = (
    await import(`templates/${templateName}/variables${isMobile ? '.m' : ''}`)
  ).default;
  return getVariables(colors);
};
