import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import tr from 'react-intl/locale-data/tr';
import de from 'react-intl/locale-data/de';
import sv from 'react-intl/locale-data/sv';
import zh from 'react-intl/locale-data/zh';
import { addLocaleData } from 'react-intl';
import deepMerge from 'deepmerge';
import i18nConfig from '../../../i18nConfig';

[en, de, es, sv, zh, tr].forEach(addLocaleData);
// removed langauges for launch [id, fr, ja, fi, pt, zh, no]

class Language {
  /**
   * Loads a language resource bundle, and matching polyfil if Intl isn't supported
   *
   * See here ...
   * https://github.com/andyearnshaw/Intl.js#intljs-and-browserifywebpack
   *
   * @param lang
   * @returns {*}
   */
  static async load(lang = App.Globals.lang) {
    try {
      let jsonFromApi;
      if (__DEV__) {
        // eslint-disable-next-line no-param-reassign
        const skinJsonFromApi = await this.loadFromPoeditor(
          lang,
          App.Globals.skin,
        );
        const wlJsonFromApi = await this.loadFromPoeditor(lang, 'whitelabel');
        if (skinJsonFromApi) {
          jsonFromApi = deepMerge(wlJsonFromApi, skinJsonFromApi);
        }
      }

      const localeData =
        jsonFromApi ||
        (await import(`i18n/${App.Globals.langFolder}/${lang}.json`));

      localStorage.setItem('locale', JSON.stringify(localeData));
      App.Globals.LOCALE = localeData;
      return localeData; // Return the locale data directly
    } catch (error) {
      location.href = `${App.Urls.Root}/${App.Features('bundle.defaultLang')}`;
      return {};
    }
  }

  static async loadFromPoeditor(lang, skin = 'whitelabel') {
    const currentSkin = i18nConfig[skin];
    const apiToken = currentSkin.API_TOKEN;
    const projectId = currentSkin.PROJECT_ID;
    const locales = currentSkin.locales;
    const selectedLang = locales?.find(l => l === lang) || locales?.[0];

    if (!selectedLang) {
      return {};
    }

    const data = new URLSearchParams({
      api_token: apiToken,
      action: 'export',
      id: projectId,
      language: selectedLang,
      type: 'key_value_json',
    });

    try {
      const response = await fetch(
        'https://api.poeditor.com/v2/projects/export',
        {
          method: 'POST',
          body: data,
        },
      );
      const res = await response.json();
      const fileUrl = res.result.url;

      if (fileUrl) {
        const jsonResponse = await fetch(fileUrl);
        const jsonContent = await jsonResponse.json();
        return jsonContent;
      }
    } catch (err) {
      console.error('Error:', err);
    }
    return {};
  }

  /**
   * Removes the language code from the beginning of the path
   * @param path
   * @returns {*}
   */
  static remove(path) {
    const { lang } = App.Globals;
    path = path.replace(/^\/+|\/+$/i, '');

    // replaces the language part of the path, when succeeded with further route uris
    // 'en/some/other/route' removes 'en/
    const regEx = new RegExp(`^\/?(${lang})/`, 'i');
    path = path.replace(regEx, '');

    // if the pathname exactly matches a language (ie. home page) remove it
    // path == 'de' remove, but path == 'deposit' donot remove
    if (path === lang) path = '';
    return path;
  }

  /**
   * Replaces the locale in the provided path
   * @param path
   * @param locale
   */
  static replace(path, lang, search, validate = true) {
    // remove leading/trailing slashes
    const pathname = path.replace(/^\/+|\/+$/i, '');
    const pathParts = pathname.split('/');
    // remove first part, which is always lang
    if (pathParts.length && (!validate || this.supported(pathParts[0]))) {
      pathParts.shift();
    }
    // join remaining parts together if there are any
    const suffix = pathParts.length ? `/${pathParts.join('/')}` : '';
    // and prepend new language
    return `/${lang}${suffix}${search}`;
  }

  static supported = lang => {
    // if null or empty not supported
    if (!lang || _.isEmpty(lang)) {
      return false;
    }
    // is one of our supported langs?
    return App.Config.locales[lang];
  };
}

export default Language;
