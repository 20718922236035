class GMT {
  init = features => {
    const sportsKey = /\/\/sports/.test(App.Urls.domain);
    const { index } = features;
    if (features.index?.gtmID) {
      const key =
        sportsKey && index?.sports?.gtmID ? index.sports.gtmID : index.gtmID;
      this.gtmTagInit(key);
    }
    if (features.index?.gtagID) {
      const key =
        sportsKey && index?.sports?.gtagID ? index.sports.gtagID : index.gtagID;
      this.gtagInit(key);
    }
  };

  gtmTagInit = id => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = document.getElementsByTagName('script')[0];
    const j = document.createElement('script');
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
    f.parentNode.insertBefore(j, f);
  };

  gtagInit = id => {
    const s = document.createElement('script');
    s.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    s.async = true;
    document.head.appendChild(s);

    setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', id);
    }, 500);
  };
}

const store = new GMT();
export default store;
