import { loadAsync } from 'app/util/Utils';

class TheTradeDesk {
  ADVERTISER_ID;
  UPIXEL_ID;
  baseScript = 'https://js.adsrvr.org/up_loader.1.1.0.js';

  init = async features => {
    if (window.ttd_dom_ready || !features.tracking.theTradeDesk.enabled) return;
    this.ADVERTISER_ID = features.tracking.theTradeDesk?.ADVERTISER_ID;
    this.UPIXEL_ID = features.tracking.theTradeDesk?.UPIXEL_ID;

    await loadAsync(this.baseScript, () => {
      ttd_dom_ready(function() {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init(
            this.ADVERTISER_ID,
            [this.UPIXEL_ID],
            'https://insight.adsrvr.org/track/up',
          );
        }
      });
    });
  };
}

const inst = new TheTradeDesk();
export default inst;
