const i18n = {
  betjets: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 646159,
    locales: ['en'],
  },
  action247: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 327505,
    locales: ['en', 'en-us'],
  },
  wynn: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 389085,
    locales: ['en', 'en-us'],
  },
  betbull: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 484013,
    locales: ['en'],
  },
  bigibet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 348257,
    locales: ['en'],
  },
  '138light': {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  gtx: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  happyluke: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  beebet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 332663,
    locales: ['en', 'ja'],
  },
  casinoleo: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 555621,
    locales: ['en', 'ja'],
  },
  devtest: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  riverigaming: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  bettabets: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  skybook365light: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  skybook365_rest: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['pt-br', 'en', 'es'],
  },
  skybook365dark: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  skybook365: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  foxbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en-us'],
  },
  blitzbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 349845,
    locales: ['en', 'en-ca', 'en-us'],
  },
  hardrock: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  baja: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 72553,
    locales: ['en', 'es-mx'],
  },
  bob: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 72743,
    locales: ['en'],
  },
  redzone: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 72747,
    locales: ['en'],
  },
  ice: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 72747, // TODO: needs its own ID
    locales: ['en'],
  },
  eurofootball: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 131445,
    locales: ['en', 'bg', 'tr', 'el'],
  },
  turfbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 169875,
    locales: ['en'],
  },
  betcart: {
    API_TOKEN: 'c5d53423b0ec4ee7c7deb35bae87e8f6',
    PROJECT_ID: 116107,
    locales: ['en', 'ar', 'de', 'it', 'fa', 'pt-br', 'es', 'sv'],
  },
  premierbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 72743,
    locales: ['en', 'de', 'es', 'it', 'fi', 'no', 'pt', 'se', 'ir'],
  },
  sbtime: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183417,
    locales: ['pt-br', 'en', 'de'],
  },
  bet2020: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183417,
    locales: ['en'],
  },
  lulabet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 526313,
    locales: ['en'],
  },
  lulabetwl: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 526313,
    locales: ['en'],
  },
  bet99: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 618035,
    locales: ['en', 'fr'],
  },
  bet99_ontario: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 627003,
    locales: ['en', 'fr'],
  },
  bet99wl: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 618035,
    locales: ['en', 'fr'],
  },
  fitzdares: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 677537,
    locales: ['en'],
  },
  palacebet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 526313,
    locales: ['en'],
  },
  magicalvegas: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 184937,
    locales: ['en'],
  },
  armchair: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 184937,
    locales: ['en'],
  },
  windcreek: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 184937,
    locales: ['en'],
  },
  sidewalk: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 184937,
    locales: ['en'],
  },
  armchairgold: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 190489,
    locales: ['en', 'tr'],
  },
  fbg: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  fibetpro: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 202109,
    locales: ['en', 'tr'],
  },
  terminal: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  dbet68: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en', 'tr'],
  },
  gencobahis: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en', 'tr'],
  },
  skybox: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 198767,
    locales: ['en'],
  },
  everybet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 212533,
    locales: ['en', 'zh-CN', 'pt-br', 'pt', 'de'],
  },
  lottoland: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  haydibas: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 202453,
    locales: ['en', 'tr'],
  },
  grbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en', 'tr'],
  },
  winit365: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  futwin: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 218891,
    locales: ['pt-br', 'pt', 'en', 'es'],
  },
  choctaw: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 216915,
    locales: ['en'],
  },
  eazibet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  mcxfair: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  bgowin: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 232595,
    locales: ['en', 'tr'],
  },
  '666bet': {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  premierpunt: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  sportech: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224107,
    locales: ['en'],
  },
  playzone365: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224107,
    locales: ['en'],
  },
  nmlottery: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224107,
    locales: ['en'],
  },
  amelco: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  tote: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 660261,
    locales: ['en', 'en-gb'],
  },
  amelcoretail: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224081,
    locales: ['en', 'en-us'],
  },
  amelcoteller: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224081,
    locales: ['en', 'en-us'],
  },
  amelco_us: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  amelcodark: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  fuboretail: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 224081,
    locales: ['en', 'en-us'],
  },
  vigtory: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 227367,
    locales: ['en', 'en-us'],
  },
  daxxbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['tr', 'en', 'ar', 'fr', 'de', 'el', 'fa', 'sv'],
  },
  stormbet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 235683,
    locales: ['en', 'pt', 'pt-br', 'de'],
  },
  ladabet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 232593,
    locales: ['en', 'tr'],
  },
  talksport: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: ['en'],
  },
  playup: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 367563,
    locales: ['en', 'en-us'],
  },
  casinoprivy: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 624911,
    locales: ['en', 'fr'],
  },
  playup_nj: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 394151,
    locales: ['en', 'en-us'],
  },
  saracen: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 513031,
    locales: ['en', 'en-us'],
  },
  saracenwl: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 513031,
    locales: ['en', 'en-us'],
  },
  shinobibet: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 701070,
    locales: ['en', 'ja', 'en-nz'],
  },
  whitelabel: {
    API_TOKEN: '3a9e71e82e571b31afa8c611164e3500',
    PROJECT_ID: 183415,
    locales: [
      'ar',
      'de',
      'el',
      'en-us',
      'en',
      'fa',
      'fr',
      'ja',
      'pt-br',
      'pt',
      'sv',
      'tr',
      'zh-CN',
    ],
  },
};

module.exports = i18n;
