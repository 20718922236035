import store from 'store';

const PARAM_NAMES = {
  BTAG: 'btag',
  SREF: 'sref',
  DEFAULTAFF: 'aff',
  ALT_BONUS_CODE: 'payload',
  BONUS_CODE: 'group',
};

const STORE_NAMES = {
  AFFILIATE_URL: 'affiliateUrl',
  REFER_A_FRIEND_CODE: 'referAFriendCode',
};

/**
 *
 * extracts a bonusCode from url query param
 * e.g. ?btag=xxx&aff=yyy&group=bonusCode123' or ?btag=xxx&aff=yyy&payload=bonusCode123'
 * also covers sref parameter for the affiliate
 * e.g. ?bTag=654557_D92E6FF69AE845C983D1BE696BE9C010&sref=ref&ref=123354
 */
class AffiliatesController {
  constructor() {}

  init = () => {
    const paramKeys = Object.keys(
      this.getParams(window.location.search.replace('?', '')),
    );
    const affiliateParams = Object.values(PARAM_NAMES);
    if (affiliateParams.some(p => paramKeys.includes(p))) {
      this.setReferer();
    }
  };

  getParams = query => {
    const urlSearchParams = new URLSearchParams(query);
    const params = Object.fromEntries(
      Array.from(urlSearchParams.entries()).map(([key, value]) => [
        key.toLowerCase(),
        value,
      ]),
    );
    return params;
  };

  getStoreParam = key => {
    const ref = this.getReferer()
      ?.split('?')
      .pop();
    if (!ref) {
      return '';
    }
    const params = this.getParams(ref);
    return params[key.toLowerCase()] || '';
  };

  clearStore = () => {
    Object.values(STORE_NAMES).forEach(v => store.remove(v));
  };

  setReferAFriendCode = code => {
    store.set(STORE_NAMES.REFER_A_FRIEND_CODE, code);
  };

  getReferAFriendCode = () => {
    return store.get(STORE_NAMES.REFER_A_FRIEND_CODE) || '';
  };

  setReferer = () => {
    store.set(STORE_NAMES.AFFILIATE_URL, window.location.href, {
      expires: App.Features('register.affiliateExpireDays'),
    });
  };

  getReferer = () => {
    return store.get(STORE_NAMES.AFFILIATE_URL) || '';
  };

  getBtag = () => {
    return this.getStoreParam(PARAM_NAMES.BTAG);
  };

  getAffiliate = () => {
    const aff = this.getStoreParam(PARAM_NAMES.DEFAULTAFF);
    if (aff) {
      return aff;
    }
    const srefParamKey = this.getStoreParam(PARAM_NAMES.SREF);
    return this.getStoreParam(srefParamKey);
  };

  getBonusCode = () => {
    return (
      this.getStoreParam(PARAM_NAMES.BONUS_CODE) ||
      this.getStoreParam(PARAM_NAMES.ALT_BONUS_CODE)
    );
  };
}

const inst = new AffiliatesController();
export default inst;
