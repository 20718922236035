import xAds from 'thirdparty/xAds';

class XAdsTracking {
  track = async (eventName = '', metaData = {}) => {
    if (!window.twq) await xAds.init({ tracking: App.Features('tracking') });

    const eventKey = App.Features(`tracking.xAds.eventKey.${eventName}`);
    if (!eventKey) return;

    twq('event', eventKey, metaData);
  };
}

const inst = new XAdsTracking();
export default inst;
