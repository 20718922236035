import { observable } from 'mobx';
import { loadAsync } from 'app/util/Utils';

class GSMTracking {
  NAME = 'gsm';

  track = (type = '', { amount = 0 }) => {
    const gsmTrackingId = App.Features('tracking.gsm.id');
    const accountId = App.session.request('accountId');
    const currency = App.session.request('currency');
    let eventType = type;
    if (!gsmTrackingId) {
      return;
    }
    let params = '';
    switch (type) {
      case 'register':
        eventType = 'register_success';
        params = `?AccountID=${accountId}`;
        break;
      case 'login':
        eventType = 'login_success';
        params = `?AccountID=${accountId}`;
        break;
      case 'deposit_success':
        eventType = 'depositconfirm';
        params = `?AccountID=${accountId}&Stake=${amount}&Currency=${currency}`;
        break;
      case 'first_deposit_success':
      case 'placebet_success':
        params = `?AccountID=${accountId}&Stake=${amount}&Currency=${currency}`;
        break;
    }

    loadAsync(
      `https://us.connextra.com/dcs/tagController/tag/${gsmTrackingId}/${eventType}${params}`,
    );
  };
}

const store = new GSMTracking();
export default store;
