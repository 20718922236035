import BingAds from 'thirdparty/bingAds';

class BingAdsTracking {
  track = (eventName, data = {}) => {
    const eventKey = App.Features(`tracking.bingAds.eventKey.${eventName}`);

    if (!eventKey) return;

    this.trackEvent(eventKey);
  };

  trackEvent = async key => {
    if (!window.UET)
      await BingAds.init({
        tracking: App.Features('tracking'),
      });

    var o = { ti: key, enableAutoSpaTracking: true };
    (o.q = window.uetq),
      (window.uetq = new UET(o)),
      window.uetq.push('pageLoad');
  };
}

const inst = new BingAdsTracking();
export default inst;
